<template>
  <!-- logo -->
  <div
    class="d-flex align-center justify-center h-full ghd-base-color-black ghd-text-white"
    style="padding: 20px"
  >
    <div
      class="text-center"
      style="width: 100%"
    >
      <img
        height="auto"
        width="200"
        src="/images/logo-oro.png"
        class="mb-16"
      />

      <div class="text-center">
        <h1
          class="text-xlarge font-weight-semibold ghd-ITCAvant"
          style="line-height: 1"
        >
          {{ appName }}
        </h1>
        <div class="text-medium ghd-ITCAvant mt-5">
          Al fine di migliorare il nostro servizio di assistenza e integrare nuovi sistemi operativi, il portale dedicato HTTPS://SUPPORT.GHDITALIA.IT/ è momentaneamente in manutenzione e tornerà attivo nel minor tempo possibile.  <br />
          Le pratiche aperte dal giorno 19/06 saranno gestite entro 21 giorni lavorativi dal momento in cui il portale tornerà disponibile.
        </div>
      </div>
      <div class="text-center mt-16">
        <span class="mt-3 text-medium ghd-ITCAvant">
          Ti ringraziamo per la collaborazione.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'

export default {
  name: 'HomePage',

  setup() {
    return {
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style scoped>

</style>
